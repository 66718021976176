import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'gatsby'

import Navigation from '../Navigation/Navigation'

import CDOLogo from '../../images/svgs/secondary-logo.svg'

import { AnimationContext } from '../../context/AnimationContext'

export default function Header () {

	const [ menuToggled, toggleMenu ] = useState(false)
	const [ animationRunning, setAnimationRunning ] = useState( true )
	const { animationEnabled } = useContext( AnimationContext )

	useEffect(() => {

		if ( !animationEnabled ) {
			setAnimationRunning( false )
		}

		setTimeout(() => {
			setAnimationRunning( false )
		}, 3000 )

	}, [ animationEnabled ])

	const handleClick = () => {

		if ( !menuToggled ) {
			const newScrollPosition = window.pageYOffset

			document.body.style.top = `-${newScrollPosition}px`
			document.body.classList.add('u-body-no-scroll')
		} else {
			const newScrollPosition = document.body.getBoundingClientRect().top

			document.body.classList.remove('u-body-no-scroll');
			document.body.style.removeProperty('top')

			window.scrollTo(0, Math.abs( newScrollPosition ))
		}

		toggleMenu( !menuToggled )
	}

	return (
		<header className={`header ${ animationRunning ? 'is-hidden' : '' }`}>

			<div className="header__container">

				<Link to="/" className={`header__title-link`} aria-label={ 'Home' }>
					<CDOLogo className="header__logo" />
				</Link>

				<div className="header__navigation-container">
					<Navigation toggleMenu={ toggleMenu } menuToggled={ menuToggled } />
				</div>

				<button className="header__mobile-menu-toggle u-show-phablet" onClick={ () =>handleClick() }>
					<div className={`hamburger ${ menuToggled ? 'active' : '' }`}>
						<span className="hamburger__box">
							<span className="hamburger__inner"></span>
						</span>
					</div>
				</button>

			</div>
		</header>
	)
}
