import React from 'react'

import CDOLogo from '../../images/svgs/secondary-logo.svg'
import BuildingIcon from '../../images/svgs/building-icon.svg'
import EmailIcon from '../../images/svgs/email-icon.svg'
import PhoneIcon from '../../images/svgs/phone-icon.svg'

export default function Footer () {

	return (
		<footer className="footer">
			<div className="footer__wrapper wrapper">
				<div className="footer__content-container footer__content-container--logo">
					<CDOLogo className="footer__logo" />
				</div>
				<div className="footer__content-container">
					<ul className="footer__list">
						<li className="footer__list-item">
							<div className="footer__list-icon-container">
								<BuildingIcon className="footer__list-icon" />
							</div>
							Malvern House, London
						</li>
						<li className="footer__list-item">
							<div className="footer__list-icon-container">
								<PhoneIcon className="footer__list-icon" />
							</div>
							<a href="tel:+447908303522" className="footer__link">+44 (0)7908303522</a>
						</li>
						<li className="footer__list-item">
							<div className="footer__list-icon-container">
								<EmailIcon className="footer__list-icon" />
							</div>
							<a href="mailto:info@cdodigital.com" className="footer__link">info@cdodigital.com</a>
						</li>
					</ul>
				</div>
				<div className="footer__content-container">
					<p className="footer__text-block">Collaboration is at the heart of what we do, and we’re always keen to work with, and support, individuals and small businesses. If you have a service you think you could offer us, or if you would like to work with us on future projects, do get in contact.</p>
				</div>
			</div>
			<div className="footer__copyright">Copyright { new Date().getFullYear() }. CDO Digital.</div>
		</footer>
	)
}