import React from 'react'
import PropTypes from 'prop-types'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Layout = ({ children }) => {

	return (
		<>
			<Header />
			<main className="main-content">
				<div className="body-content">
					{children}
					<Footer />
				</div>
			</main>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout