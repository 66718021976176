import React, { createContext, useState } from 'react';

export const AnimationContext = createContext( null )

export default ( { children } ) => {

	const [ animationEnabled, setAnimationEnabled ] = useState( true )
	const [ isHomepage, setIsHomepage ] = useState( true )

	return (
		<AnimationContext.Provider value={{ animationEnabled, setAnimationEnabled, isHomepage, setIsHomepage }}>
			{ children }
		</AnimationContext.Provider>
	)
}