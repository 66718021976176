import React from 'react'
import { Link } from 'gatsby'

export default function NavLink( props ) {

	const { navItem, toggleMenu } = props

	const handleClick = () => {
		document.body.classList.remove('u-body-no-scroll');
		document.body.style.removeProperty('top')

		toggleMenu( false )
	}

	return (
		<Link
			to={ `/#${ navItem.link }` }
			className="navigation__link"
			activeClassName="active"
			onClick={ () => handleClick() }>
			{ navItem.name }
		</Link>
	)
}