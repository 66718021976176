import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import HomepageLink from './HomepageLink'
import NavLink from './NavLink'

import { AnimationContext } from '../../context/AnimationContext'

export default function Navigation( props ) {

	const { toggleMenu, menuToggled } = props
	const { isHomepage } = useContext( AnimationContext )

	const queryData = useStaticQuery(graphql`
		query NavQuery {
			site {
				siteMetadata {
					menuLinks {
						name
						link
					}
				}
			}
		}
	`)

	const data = queryData.site.siteMetadata.menuLinks

	return (
		<div className={`navigation ${ menuToggled ? 'is-open' : '' }`}>
			<nav className="navigation__menu">
				<div className="navigation__wrapper">
					<ul className="navigation__list">
						{ data.map( ( navItem, index ) => (
							<React.Fragment key={ index }>
								<li className="navigation__item">
									{ isHomepage ? (
										<HomepageLink navItem={ navItem } toggleMenu={ toggleMenu } />
									): (
										<NavLink navItem={ navItem } toggleMenu={ toggleMenu } />
									) }
									
								</li>
							</React.Fragment>
						) ) }
					</ul>
				</div>
			</nav>
		</div>
	)
}