import React from 'react'
import { Link } from 'react-scroll'

export default function HomepageLink( props ) {

	const { navItem, toggleMenu } = props

	const handleClick = event => {
		event.preventDefault()

		document.body.classList.remove('u-body-no-scroll');
		document.body.style.removeProperty('top')

		toggleMenu( false )
	}

	return (
		<Link
			to={ navItem.link }
			className="navigation__link"
			spy={ true }
			smooth={ true }
			duration={ 500 }
			onClick={ e => handleClick( e ) }>
			{ navItem.name }
		</Link>
	)
}