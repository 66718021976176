import React from 'react'

import AnimationContextProvider from './src/context/AnimationContext'

import Layout from './src/components/Layout/Layout'
import CookiePolicy from './src/components/Cookie/CookiePolicy'

import './src/styles/toolkit.scss'

export const wrapRootElement = ({ element }) => (
	<AnimationContextProvider>
		<Layout>
			{ element }
			<CookiePolicy />
		</Layout>
	</AnimationContextProvider>
)